import React, { useEffect, useState } from 'react';
import api from 'services/api';
import GomusLogo from '../../../assets/img/logo.png';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';

export default function PublicTerms() {
  return (
    <>
      <Link to="/">
        <img src={GomusLogo} style={{padding: '20px', margin: 'auto', marginBottom: '30px'}} />
      </Link>
      <h1 className="text-center font-bold text-2xl text-black mb-4">
        Termos de uso e políticas de privacidade.
      </h1>
      <div style={{padding: '40px'}}>
        <p className="max-h-64 overflow-auto m-4"></p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.295",
            textAlign: "right",
            marginTop: "0pt",
            marginBottom: "8pt"
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap"
            }}
          >
            Termo de uso GOMUS JUKEBOX v. 01
          </span>
        </p>
        <p />
        <p
          dir="ltr"
          style={{
            lineHeight: "1.295",
            textAlign: "justify",
            marginTop: "0pt",
            marginBottom: "8pt"
          }}
          id="docs-internal-guid-c2afecb7-7fff-aeff-9acc-101d314a41a7"
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap"
            }}
          >
            TERMO DE USO
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.295",
            textAlign: "justify",
            marginTop: "0pt",
            marginBottom: "8pt"
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap"
            }}
          >
            Qualificações
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.295",
            textAlign: "justify",
            marginTop: "0pt",
            marginBottom: "8pt"
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap"
            }}
          >
            O website e aplicativo com denominação GOMUS JUKEBOX são de propriedade da
            Razão Social BPM 2009 Soluções Musicais Ltda., inscrita sob o número de
            CNPJ 10.792.466/0002-01 e o usuário concorda com estes termos, bem como
            com a Política de Privacidade, disponível no site, aplicativo ou
            encaminhada via-e-mail.
          </span>
        </p>
        <ol style={{ marginTop: 0, marginBottom: 0 }}>
          <li
            dir="ltr"
            style={{
              listStyleType: "upper-roman",
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
              paddingLeft: "18pt"
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.295",
                textAlign: "justify",
                marginTop: "0pt",
                marginBottom: "8pt"
              }}
              role="presentation"
            >
              <span
                style={{
                  fontSize: "12pt",
                  fontFamily: "Calibri,sans-serif",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "underline",
                  WebkitTextDecorationSkip: "none",
                  textDecorationSkipInk: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap"
                }}
              >
                Objeto da prestação
              </span>
            </p>
          </li>
        </ol>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.295",
            textAlign: "justify",
            marginTop: "0pt",
            marginBottom: "8pt"
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap"
            }}
          >
            A GOMUS JUKEBOX oferece serviço de facilitação de escolha de músicas para tocar em lojas parceiras.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.295",
            textAlign: "justify",
            marginTop: "0pt",
            marginBottom: "8pt"
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap"
            }}
          >
            As informações prestadas nas plataformas disponibilizadas pela GOMUS
            JUKEBOX têm por objetivo o suporte do usuário para melhor experiência
            em suas compras no varejo.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.295",
            textAlign: "justify",
            marginTop: "0pt",
            marginBottom: "8pt"
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap"
            }}
          >
            Desta forma, a GOMUS JUKEBOX não oferece serviços de streaming ou de
            qualquer natureza musica, que não a escolha da ordem de execução de
            músicas já pré-definidas e presentes em listas nos estabelecimentos
            parceiros.
          </span>
        </p>
        <ol style={{ marginTop: 0, marginBottom: 0 }} start={2}>
          <li
            dir="ltr"
            style={{
              listStyleType: "upper-roman",
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
              paddingLeft: "18pt"
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.295",
                textAlign: "justify",
                marginTop: "0pt",
                marginBottom: "8pt"
              }}
              role="presentation"
            >
              <span
                style={{
                  fontSize: "12pt",
                  fontFamily: "Calibri,sans-serif",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "underline",
                  WebkitTextDecorationSkip: "none",
                  textDecorationSkipInk: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap"
                }}
              >
                Registro de usuário
              </span>
            </p>
          </li>
        </ol>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.295",
            textAlign: "justify",
            marginTop: "0pt",
            marginBottom: "8pt"
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap"
            }}
          >
            Para acesso aos serviços da plataforma GOMUS JUKEBOX via website ou
            aplicativo, o usuário precisará fornecer informações pessoais para
            registro. Por esta razão, o usuário declara ciência e concordância com a
            Política de Privacidade disponível em{" "}
          </span>
          <a
            href="https://jukebox.gomus.com.br/app/policy"
            style={{ textDecoration: "none" }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#1155cc",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              https://jukebox.gomus.com.br/app/policy
            </span>
          </a>
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap"
            }}
          >
            , inclusive da forma com que seus dados serão tratados no decorrer da
            prestação de serviço.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.295",
            textAlign: "justify",
            marginTop: "0pt",
            marginBottom: "8pt"
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap"
            }}
          >
            Para melhor atendimento, a GOMUS JUKEBOX solicitará o nome completo,
            endereço de e-mail, gênero, data de nascimento e informações relevantes
            para o perfil do usuário, de acordo com a Política de Privacidade e com
            a possibilidade de requerer novas informações em caso de necessidade,
            visando sempre o correto funcionamento e qualidade do serviço prestado.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.295",
            textAlign: "justify",
            marginTop: "0pt",
            marginBottom: "8pt"
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap"
            }}
          >
            Os serviços prestados pela GOMUS JUKEBOX são focados para o público maior
            de 18 (dezoito) anos de idade e por isto, caso o usuário tenha idade
            inferior, poderá registrar-se com seu próprio nome, porém, sua conta será
            necessariamente vinculada a representante legal com mais de 18 (dezoito)
            anos de idade.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.295",
            textAlign: "justify",
            marginTop: "0pt",
            marginBottom: "8pt"
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap"
            }}
          >
            Após o preenchimento de todos os dados solicitados, a GOMUS JUKEBOX poderá
            requerer confirmação de cadastro para validação, via e-mail, visando maior
            segurança do usuário. Caso a mesma não se confirme, o usuário não será
            criado e não terá acesso aos serviços disponíveis.
          </span>
        </p>
        <ol style={{ marginTop: 0, marginBottom: 0 }} start={3}>
          <li
            dir="ltr"
            style={{
              listStyleType: "upper-roman",
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
              paddingLeft: "18pt"
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.295",
                textAlign: "justify",
                marginTop: "0pt",
                marginBottom: "8pt"
              }}
              role="presentation"
            >
              <span
                style={{
                  fontSize: "12pt",
                  fontFamily: "Calibri,sans-serif",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "underline",
                  WebkitTextDecorationSkip: "none",
                  textDecorationSkipInk: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap"
                }}
              >
                Uso do site e aplicativo
              </span>
            </p>
          </li>
        </ol>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.295",
            textAlign: "justify",
            marginTop: "0pt",
            marginBottom: "8pt"
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap"
            }}
          >
            Uma vez que o usuário esteja registrado, terá acesso aos serviços da GOMUS
            JUKEBOX.
          </span>
        </p>
        <ol style={{ marginTop: 0, marginBottom: 0 }} start={6}>
          <li
            dir="ltr"
            style={{
              listStyleType: "upper-roman",
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
              paddingLeft: "18pt"
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.295",
                textAlign: "justify",
                marginTop: "0pt",
                marginBottom: "8pt"
              }}
              role="presentation"
            >
              <span
                style={{
                  fontSize: "12pt",
                  fontFamily: "Calibri,sans-serif",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "underline",
                  WebkitTextDecorationSkip: "none",
                  textDecorationSkipInk: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap"
                }}
              >
                Conteúdo publicado
              </span>
            </p>
          </li>
        </ol>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.295",
            textAlign: "justify",
            marginTop: "0pt",
            marginBottom: "8pt"
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap"
            }}
          >
            A GOMUS JUKEBOX, conforme Política de Privacidade, zela pelo sigilo de
            todas as informações pessoais do usuário, mas poderá usar e publicar
            conteúdo permitido pela legislação vigente, por Ordem Judicial ou
            determinação de Autoridade Pública Competente, de forma compatível com a
            Política de Privacidade da GOMUS JUKEBOX, tanto para informações pessoais
            quanto para sensíveis.
          </span>
        </p>
        <ol style={{ marginTop: 0, marginBottom: 0 }} start={7}>
          <li
            dir="ltr"
            style={{
              listStyleType: "upper-roman",
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
              paddingLeft: "18pt"
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.295",
                textAlign: "justify",
                marginTop: "0pt",
                marginBottom: "8pt"
              }}
              role="presentation"
            >
              <span
                style={{
                  fontSize: "12pt",
                  fontFamily: "Calibri,sans-serif",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "underline",
                  WebkitTextDecorationSkip: "none",
                  textDecorationSkipInk: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap"
                }}
              >
                Tratamento de dados&nbsp;
              </span>
            </p>
          </li>
        </ol>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.295",
            textAlign: "justify",
            marginTop: "0pt",
            marginBottom: "8pt"
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap"
            }}
          >
            A GOMUS JUKEBOX coletará dados dos usuários, sejam pessoais ou sensíveis,
            e se compromete integralmente a cumprir as Políticas de Privacidade
            internas{" "}
          </span>
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap"
            }}
          >
            bem como garantir os direitos do usuário
          </span>
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap"
            }}
          >
            , de acordo com a Lei Federal 13.709/18. (Lei Geral de Proteção de Dados){" "}
          </span>
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap"
            }}
          >
            Para mais informações de como os dados são coletados, tratados e os
            direitos do usuário, vide https://jukebox.gomus.com.br/app/policy.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.295",
            textAlign: "justify",
            marginTop: "0pt",
            marginBottom: "8pt"
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap"
            }}
          >
            Por meio deste Termo, o usuário consente com o uso de seus dados conforme
            as Políticas de Privacidade da GOMUS JUKEBOX.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.295",
            textAlign: "justify",
            marginTop: "0pt",
            marginBottom: "8pt"
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap"
            }}
          >
            Os dados pessoais colhidos em razão da interação do usuário com as
            plataformas da GOMUS JUKEBOX não serão comercializados ou compartilhados
            com quaisquer entidades sem requerimento de Autoridade Competente.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.295",
            textAlign: "justify",
            marginTop: "0pt",
            marginBottom: "8pt"
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap"
            }}
          >
            Para tratamento dos dados, a GOMUS JUKEBOX poderá compartilhar os dados
            pessoais ou sensíveis de forma internacional apenas com operadora
            terceirizada que esteja adequada a legislação de proteção de dados do
            Brasil.&nbsp;
          </span>
        </p>
        <ol style={{ marginTop: 0, marginBottom: 0 }} start={8}>
          <li
            dir="ltr"
            style={{
              listStyleType: "upper-roman",
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
              paddingLeft: "18pt"
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.295",
                textAlign: "justify",
                marginTop: "0pt",
                marginBottom: "8pt"
              }}
              role="presentation"
            >
              <span
                style={{
                  fontSize: "12pt",
                  fontFamily: "Calibri,sans-serif",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "underline",
                  WebkitTextDecorationSkip: "none",
                  textDecorationSkipInk: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap"
                }}
              >
                Propriedade intelectual
              </span>
            </p>
          </li>
        </ol>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.295",
            textAlign: "justify",
            marginTop: "0pt",
            marginBottom: "8pt"
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap"
            }}
          >
            Todo o conteúdo do site e aplicativo da GOMUS JUKEBOX são de única e
            exclusiva propriedade da GOMUS JUKEBOX, que está protegida por toda
            legislação relativa a direitos autorais, tais como e não exclusivamente a
            Lei Federal 9.610/98 (Lei de Direitos Autorais) e Lei Federal 9.279/96
            (Lei da Propriedade Industrial), em âmbito nacional e internacional.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.295",
            textAlign: "justify",
            marginTop: "0pt",
            marginBottom: "8pt"
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap"
            }}
          >
            As marcas, logotipos, slogans, textos, imagens, sons,{" "}
          </span>
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "italic",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap"
            }}
          >
            softwares{" "}
          </span>
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap"
            }}
          >
            e afins, que constituem a atividade da GOMUS JUKEBOX em site e aplicativos
            e quaisquer outros de sua titularidade, são única e exclusivamente de
            propriedade da GOMUS JUKEBOX e autorizados, necessitando de consentimento
            expresso da GOMUS JUKEBOX para uso de sua marca em qualquer vinculação
            seja jornalística, publicitária o quaisquer fins comerciais.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.295",
            textAlign: "justify",
            marginTop: "0pt",
            marginBottom: "8pt"
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap"
            }}
          >
            Nos casos em que a GOMUS JUKEBOX entenda haver violação de direitos
            autorais, será encaminhada notificação extrajudicial ao usuário ou
            violador de tais direitos, para que o mesmo cesse a violação.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.295",
            textAlign: "justify",
            marginTop: "0pt",
            marginBottom: "8pt"
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap"
            }}
          >
            Caso o usuário ou terceiro entenda que a GOMUS JUKEBOX esteja violando
            direito autoral, deve o interessado encaminhar notificação à GOMUS
            JUKEBOX, por escrito, endereçada ao representante de Direitos Autorais ou
            responsável por Tratamento de Dados, cujos dados estão indicados em
            “Representantes”, com indicação da identificação do trabalho ou conteúdo
            protegido, informações de contato e número de registro de patente, se
            houver.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.295",
            textAlign: "justify",
            marginTop: "0pt",
            marginBottom: "8pt"
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap"
            }}
          >
            A GOMUS JUKEBOX poderá encerrar ou bloquear contas que apresentarem
            violações de direitos autorais.
          </span>
        </p>
        <ol style={{ marginTop: 0, marginBottom: 0 }} start={9}>
          <li
            dir="ltr"
            style={{
              listStyleType: "upper-roman",
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
              paddingLeft: "18pt"
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.295",
                textAlign: "justify",
                marginTop: "0pt",
                marginBottom: "8pt"
              }}
              role="presentation"
            >
              <span
                style={{
                  fontSize: "12pt",
                  fontFamily: "Calibri,sans-serif",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "underline",
                  WebkitTextDecorationSkip: "none",
                  textDecorationSkipInk: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap"
                }}
              >
                Responsabilidades
              </span>
            </p>
          </li>
        </ol>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.295",
            textAlign: "justify",
            marginTop: "0pt",
            marginBottom: "8pt"
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap"
            }}
          >
            A GOMUS JUKEBOX oferece serviço de facilitação de escolha de músicas para tocar em lojas parceiras.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.295",
            textAlign: "justify",
            marginTop: "0pt",
            marginBottom: "8pt"
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap"
            }}
          >
            A GOMUS JUKEBOX trabalha com as tecnologias de segurança recomendadas pelo
            mercado e garante a segurança no limite da fornecido pela tecnologia, não
            sendo responsável por eventuais vírus ou{" "}
          </span>
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "italic",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap"
            }}
          >
            malwares
          </span>
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap"
            }}
          >
            {" "}
            baixados fora do escopo de programação da GOMUS JUKEBOX.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.295",
            textAlign: "justify",
            marginTop: "0pt",
            marginBottom: "8pt"
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap"
            }}
          >
            Os diretores, empregados, prepostos, conselheiros, terceirizados ou
            qualquer agente ligado à GOMUS JUKEBOX não responderão por quaisquer danos
            decorrentes da incapacidade do usuário de usar o site ou aplicativo,
            incluindo mas não exclusivamente danos diretos, indiretos, especiais,
            incidentais, consequenciais ou punitivos, incluindo lesões corporais,
            dores, sofrimento, angústia emocional, perda de receitas, lucros
            cessantes, perda de negócios ou lucros antecipados, perda de uso, dano à
            imagem, perda de dados causados por atos ilícitos, ou infrações
            contratuais.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.295",
            textAlign: "justify",
            marginTop: "0pt",
            marginBottom: "8pt"
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap"
            }}
          >
            O usuário concorda em indenizar, defender e resguardar a GOMUS JUKEBOX,
            parceiros comerciais e seus respectivos diretores, conselheiros,
            empregados, contratados, prepostos, licenciantes, fornecedores, sucessores
            e cessionários, com relação a qualquer reivindicação, demanda,
            responsabilidade, disputa, dano, custo, despesa ou prejuízo, incluindo
            honorários advocatícios e custas judiciais razoáveis, decorrentes ou de
            qualquer forma relacionados à sua violação destes Termos de Uso ou seu
            acesso ou uso do Site ou aplicativo.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.295",
            textAlign: "justify",
            marginTop: "0pt",
            marginBottom: "8pt"
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap"
            }}
          >
            Cada parte responderá, perante a outra, pelos danos que der causa, por
            suas ações ou omissões, violações contratuais e legais, indenizado por
            perdas e danos comprovados.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.295",
            textAlign: "justify",
            marginTop: "0pt",
            marginBottom: "8pt"
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap"
            }}
          >
            A GOMUS JUKEBOX não será responsabilizada, e, portanto, não responderá por
            perdas e danos nos casos em que os serviços prestados restarem suspensos
            ou indisponíveis por casos fortuitos.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.295",
            textAlign: "justify",
            marginTop: "0pt",
            marginBottom: "8pt"
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap"
            }}
          >
            A GOMUS JUKEBOX não garante que as informações inseridas em seus sistemas
            são autênticas, por isto pede os dados pessoais que tratam a Política de
            Privacidade, porém, não será responsabilizada caso as informações
            inseridas pelo usuário sejam falsas. Caso tal fato seja constatado, poderá
            a GOMUS JUKEBOX bloquear ou excluir a conta do usuário.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.295",
            textAlign: "justify",
            marginTop: "0pt",
            marginBottom: "8pt"
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap"
            }}
          >
            Na hipótese de o usuário ser menor de 18 (dezoito) anos de idade, seu
            representante legal será integralmente responsável pelo menor e anui com
            todas as disposições e responsabilidades deste instrumento.
          </span>
        </p>
        <ol style={{ marginTop: 0, marginBottom: 0 }} start={10}>
          <li
            dir="ltr"
            style={{
              listStyleType: "upper-roman",
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
              paddingLeft: "18pt"
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.295",
                textAlign: "justify",
                marginTop: "0pt",
                marginBottom: "8pt"
              }}
              role="presentation"
            >
              <span
                style={{
                  fontSize: "12pt",
                  fontFamily: "Calibri,sans-serif",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "underline",
                  WebkitTextDecorationSkip: "none",
                  textDecorationSkipInk: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap"
                }}
              >
                Alcance territorial
              </span>
            </p>
          </li>
        </ol>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.295",
            textAlign: "justify",
            marginTop: "0pt",
            marginBottom: "8pt"
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap"
            }}
          >
            As plataformas de GOMUS JUKEBOX, de site e aplicativo tem alcance mundial
            para qualquer indivíduo com acesso à internet, porém o presente serviço
            possui alcance de atuação apenas em território brasileiro, podendo o
            usuário agendar consultas quando localizado em outros países, para
            atendimento na rede castrada de instituições médicas da GOMUS JUKEBOX em
            território nacional.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.295",
            textAlign: "justify",
            marginTop: "0pt",
            marginBottom: "8pt"
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap"
            }}
          >
            A GOMUS JUKEBOX não declara ou garante que o Conteúdo do site e aplicativo
            estejam em conformidade com as leis de outros países senão o Brasil. Caso
            o usuário escolha por acessar os conteúdos da GOMUS JUKEBOX em território
            estrangeiro, fará por sua conta e risco. A GOMUS JUKEBOX se responsabiliza
            pelo cumprimento da legislação brasileira.
          </span>
        </p>
        <ol style={{ marginTop: 0, marginBottom: 0 }} start={11}>
          <li
            dir="ltr"
            style={{
              listStyleType: "upper-roman",
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
              paddingLeft: "18pt"
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.295",
                textAlign: "justify",
                marginTop: "0pt",
                marginBottom: "8pt"
              }}
              role="presentation"
            >
              <span
                style={{
                  fontSize: "12pt",
                  fontFamily: "Calibri,sans-serif",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "underline",
                  WebkitTextDecorationSkip: "none",
                  textDecorationSkipInk: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap"
                }}
              >
                Término e bloqueio
              </span>
            </p>
          </li>
        </ol>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.295",
            textAlign: "justify",
            marginTop: "0pt",
            marginBottom: "8pt"
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap"
            }}
          >
            A GOMUS JUKEBOX poderá bloquear, com a suspensão temporária do acesso do
            usuário ao sistema, bem como encerrar conta a seu exclusivo critério, de
            forma integral ou parcial, pelos motivos que julgar necessário, com ou sem
            justa causa, inclusive por violação deste termo.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.295",
            textAlign: "justify",
            marginTop: "0pt",
            marginBottom: "8pt"
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap"
            }}
          >
            Na hipótese de rescisão deste instrumento as disposições relativas a
            indenizações, direitos autorais, garantias e limitações de
            responsabilidade estabelecidas neste instrumento permanecem vigentes.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.295",
            textAlign: "justify",
            marginTop: "0pt",
            marginBottom: "8pt"
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap"
            }}
          >
            O usuário bloqueado ou excluído será notificado via aplicativo ou site ou
            outra forma de contato que convir à GOMUS JUKEBOX.&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.295",
            textAlign: "justify",
            marginTop: "0pt",
            marginBottom: "8pt"
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap"
            }}
          >
            Caso o usuário opte pela exclusão de seu cadastro dos sistemas da GOMUS
            JUKEBOX, seus dados armazenados serão anonimizados para sua segurança.
          </span>
        </p>
        <ol style={{ marginTop: 0, marginBottom: 0 }} start={12}>
          <li
            dir="ltr"
            style={{
              listStyleType: "upper-roman",
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
              paddingLeft: "18pt"
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.295",
                textAlign: "justify",
                marginTop: "0pt",
                marginBottom: "8pt"
              }}
              role="presentation"
            >
              <span
                style={{
                  fontSize: "12pt",
                  fontFamily: "Calibri,sans-serif",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "underline",
                  WebkitTextDecorationSkip: "none",
                  textDecorationSkipInk: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap"
                }}
              >
                Foro de eleição
              </span>
            </p>
          </li>
        </ol>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.295",
            textAlign: "justify",
            marginTop: "0pt",
            marginBottom: "8pt"
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap"
            }}
          >
            Este termo será regido e interpretado conformidade com a legislação
            brasileira, renunciando as partes a direitos e disposições possíveis de
            interpretação em lei ou norma diversa da brasileira. As partes elegem o
            Foro de São Paulo, como foro exclusivo para conhecer e julgar quaisquer
            pendências judiciais.&nbsp;
          </span>
        </p>
        <ol style={{ marginTop: 0, marginBottom: 0 }} start={13}>
          <li
            dir="ltr"
            style={{
              listStyleType: "upper-roman",
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
              paddingLeft: "18pt"
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.295",
                textAlign: "justify",
                marginTop: "0pt",
                marginBottom: "8pt"
              }}
              role="presentation"
            >
              <span
                style={{
                  fontSize: "12pt",
                  fontFamily: "Calibri,sans-serif",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "underline",
                  WebkitTextDecorationSkip: "none",
                  textDecorationSkipInk: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap"
                }}
              >
                Concordância
              </span>
            </p>
          </li>
        </ol>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.295",
            textAlign: "justify",
            marginTop: "0pt",
            marginBottom: "8pt"
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap"
            }}
          >
            O usuário e a GOMUS JUKEBOX concordam com todas as disposições presentes
            neste instrumento e desta forma declaram o expresso consentimento e
            entende que a ausência de consentimento inviabiliza a experiência de uso
            das plataformas GOMUS JUKEBOX, e assim, é condição para tanto.
          </span>
        </p>
        <ol style={{ marginTop: 0, marginBottom: 0 }} start={14}>
          <li
            dir="ltr"
            style={{
              listStyleType: "upper-roman",
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
              paddingLeft: "18pt"
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.295",
                textAlign: "justify",
                marginTop: "0pt",
                marginBottom: "8pt"
              }}
              role="presentation"
            >
              <span
                style={{
                  fontSize: "12pt",
                  fontFamily: "Calibri,sans-serif",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "underline",
                  WebkitTextDecorationSkip: "none",
                  textDecorationSkipInk: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap"
                }}
              >
                Alterações
              </span>
            </p>
          </li>
        </ol>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.295",
            textAlign: "justify",
            marginTop: "0pt",
            marginBottom: "8pt"
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap"
            }}
          >
            A GOMUS JUKEBOX poderá alterar este Termo de Uso, bem como a Política de
            Privacidade mediante envio da atualização e publicação no site
            institucional da GOMUS JUKEBOX. O uso contínuo significará ciência e
            aceite com o termo de uso em vigor. Caso negativa com alterações, poderão
            usuário excluir sua conta.
          </span>
        </p>
        <ol style={{ marginTop: 0, marginBottom: 0 }} start={15}>
          <li
            dir="ltr"
            style={{
              listStyleType: "upper-roman",
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
              paddingLeft: "18pt"
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.295",
                textAlign: "justify",
                marginTop: "0pt",
                marginBottom: "8pt"
              }}
              role="presentation"
            >
              <span
                style={{
                  fontSize: "12pt",
                  fontFamily: "Calibri,sans-serif",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "underline",
                  WebkitTextDecorationSkip: "none",
                  textDecorationSkipInk: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap"
                }}
              >
                Métodos de uso
              </span>
            </p>
          </li>
        </ol>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.295",
            textAlign: "justify",
            marginTop: "0pt",
            marginBottom: "8pt"
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap"
            }}
          >
            Os serviços das plataformas GOMUS JUKEBOX estão disponíveis via
            aplicativo, para download na Apple Store ou Google Play, ou acesso pelo
            website disponível na https://jukebox.gomus.com.br/.
          </span>
        </p>
        <ol style={{ marginTop: 0, marginBottom: 0 }} start={16}>
          <li
            dir="ltr"
            style={{
              listStyleType: "upper-roman",
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
              paddingLeft: "18pt"
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.295",
                textAlign: "justify",
                marginTop: "0pt",
                marginBottom: "8pt"
              }}
              role="presentation"
            >
              <span
                style={{
                  fontSize: "12pt",
                  fontFamily: "Calibri,sans-serif",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "underline",
                  WebkitTextDecorationSkip: "none",
                  textDecorationSkipInk: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap"
                }}
              >
                Representantes
              </span>
            </p>
          </li>
        </ol>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.295",
            textAlign: "justify",
            marginTop: "0pt",
            marginBottom: "8pt"
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "#ffffff",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap"
            }}
          >
            Representante de Direitos Autorais: Delano Amorim
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.295",
            textAlign: "justify",
            marginTop: "0pt",
            marginBottom: "8pt"
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "#ffffff",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap"
            }}
          >
            Representante de Proteção de Dados:
          </span>
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "#ffffff",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap"
            }}
          >
            {" "}
            Delano Amorim
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.295",
            textAlign: "justify",
            marginTop: "0pt",
            marginBottom: "8pt"
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "#ffff00",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap"
            }}
          />
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.295",
            textAlign: "justify",
            marginTop: "0pt",
            marginBottom: "8pt"
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "#ffff00",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap"
            }}
          />
        </p>
        <p />
        <p />
        <p
          data-f-id="pbf"
          style={{
            textAlign: "center",
            fontSize: 14,
            marginTop: 30,
            opacity: "0.65",
            fontFamily: "sans-serif"
          }}
        />
        <p />
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </>
  );
}
