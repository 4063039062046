import React, { useEffect, useState } from 'react';
import { Formik, ErrorMessage } from 'formik';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import useGet from 'services/hooks/useGet';
import useGetList from 'services/hooks/useGetList';
import api from 'services/api';
import { schema } from './schema';
import Select from 'components/Forms/Select';
import { useProfile } from 'services/profile/getProfile';
import ReactTooltip from 'react-tooltip';
import InputMask from 'react-input-mask';
import axios from 'axios';

function ModalForm({
  closeModal,
  onSuccess = () => {},
  identifier: storeIdentifier,
}) {
  const { user } = useProfile();
  const [action, setAction] = useState(1);
  const [localizationError, setLocalizationError] = useState('');
  const [isLoadingGeolocation, setIsLoadingGeolocation] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const { data: store } = useGet({
    route: `/adm/store/${storeIdentifier}/show`,
  });

  const {
    data: companies,
    getItems,
    isLoading: isLoadingList,
    meta,
  } = useGetList({
    route: `adm/company/list?&page_size=999999999`,
  });

  const getStateList = () => {
    api
      .get('public/geolocation/state/list?&page_size=999999999')
      .then((res) => {
        setStateList(res.data.data);
      })
  };

  const loadCityList = (state) => {
    api.get(`public/geolocation/state/${state}/cities?page_size=1000000`).then(result => {
      let cityOptions = [];
      result.data.data.map(city =>
          cityOptions.push({
              value: city.id,
              label: city.name,
          })
      );
      // if (store.city.state.id !== state) {
      //   setFieldValue("city", "");
      // }
      setCityList(cityOptions);
    });
  };

  useEffect(() => {
    getItems();
    getStateList();
  }, [action]);

  const onSubmit = ({ values, setSubmitting }) => {
    const path = storeIdentifier
      ? `adm/store/${storeIdentifier}/edit`
      : `adm/store/new`;

    api
      .post(path, values)
      .then(() => {
        toast.success(
          `Loja ${storeIdentifier ? 'editada' : 'criada'} com sucesso`
        );
        onSuccess();
        closeModal();
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      <main>
        <ReactTooltip />
        {(!storeIdentifier || (storeIdentifier && !!store)) && (
          <Formik
            enableReinitialize
            initialValues={{
              name: store?.name || '',
              latitude: store?.latitude || '',
              longitude: store?.longitude || '',
              token: store?.token || '',
              streetCode: store?.streetCode || '',
              streetName: store?.streetName || '',
              streetNumber: store?.streetNumber || '',
              streetDistrict: store?.streetDistrict || '',
              streetComplement: store?.streetComplement || '',
              state: store?.city?.state.id || '',
              city: store?.city?.id || '',
              company: store?.company?.identifier || '',
            }}
            validationSchema={schema}
            onSubmit={(values, { setSubmitting }) => {
              onSubmit({ values, setSubmitting });
            }}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <form autoComplete="off" onSubmit={handleSubmit}>
                <div className="flex flex-wrap">
                  <div className="w-full md:w-1/2 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="name"
                      >
                        Título
                        <input
                          value={values.name}
                          name="name"
                          type="text"
                          maxLength="255"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </label>
                      <ErrorMessage
                        component="p"
                        className="text-red-500 mb-4 font-light text-xs"
                        name="name"
                      />
                    </div>
                  </div>
                  <div className="w-full md:w-1/2 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="streetCode"
                      >
                        CEP
                        <InputMask
                          mask="99999-999"
                          value={values.streetCode}
                          name="streetCode"
                          type="text"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          onChange={(e) => {
                            var value = e.target.value.replace(/\D/g, "");
                            setFieldValue('streetCode', value)

                            if (value.length === 8) {
                              axios.get('https://viacep.com.br/ws/' + value + '/json/')
                              .then(res => {
                                if (res.status == 200) {
                                  setFieldValue('streetName', res.data.logradouro)
                                  setFieldValue('streetDistrict', res.data.bairro)

                                  var stateValue = {};
                                  stateList.forEach(state => {
                                    if (state.initials == res.data.uf) {
                                      stateValue = {
                                        value: state.id,
                                        label: state.name
                                      };
                                    }
                                  });
                                  if (stateValue.value) {
                                    setFieldValue('state', stateValue);
                                    api.get(`public/geolocation/state/${stateValue.value}/cities?page_size=1000000`)
                                      .then(result => {
                                        let cityOptions = [];
                                        result.data.data.map(city =>
                                            cityOptions.push({
                                                value: city.id,
                                                label: city.name,
                                            })
                                        );
                                        setCityList(cityOptions);

                                        var cityValue = {};
                                        cityOptions.forEach(city => {
                                          if (city.label == res.data.localidade) {
                                            cityValue = {
                                              value: city.value,
                                              label: city.label
                                            };
                                          }
                                        });
                                        if (cityValue.value) {
                                          setFieldValue('city', cityValue)
                                        }
                                      })
                                    ;
                                  }
                                }
                              })
                            }
                          }}
                          onBlur={(e) => {
                            handleBlur(e)
                          }}
                        />
                      </label>
                      <ErrorMessage
                        component="p"
                        className="text-red-500 mb-4 font-light text-xs"
                        name="streetCode"
                      />
                    </div>
                  </div>
                  <div className="w-full md:w-1/2 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="streetName"
                      >
                        Rua
                        <input
                          value={values.streetName}
                          name="streetName"
                          type="text"
                          maxLength="255"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </label>
                      <ErrorMessage
                        component="p"
                        className="text-red-500 mb-4 font-light text-xs"
                        name="streetName"
                      />
                    </div>
                  </div>
                  <div className="w-full md:w-1/2 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="streetNumber"
                      >
                        Número
                        <input
                          value={values.streetNumber}
                          name="streetNumber"
                          type="text"
                          maxLength="255"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </label>
                      <ErrorMessage
                        component="p"
                        className="text-red-500 mb-4 font-light text-xs"
                        name="streetNumber"
                      />
                    </div>
                  </div>
                  <div className="w-full md:w-1/2 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="streetDistrict"
                      >
                        Bairro
                        <input
                          value={values.streetDistrict}
                          name="streetDistrict"
                          type="text"
                          maxLength="255"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </label>
                      <ErrorMessage
                        component="p"
                        className="text-red-500 mb-4 font-light text-xs"
                        name="streetDistrict"
                      />
                    </div>
                  </div>
                  <div className="w-full md:w-1/2 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="streetComplement"
                      >
                        Complemento
                        <input
                          value={values.streetComplement}
                          name="streetComplement"
                          type="text"
                          maxLength="255"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </label>
                      <ErrorMessage
                        component="p"
                        className="text-red-500 mb-4 font-light text-xs"
                        name="streetComplement"
                      />
                    </div>
                  </div>
                  <div className="w-full md:w-1/2 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="state"
                      >
                        Estado
                        <Select
                          handleBlur={() => {
                            if (values.state) {
                              loadCityList(values.state)
                            }
                          }}
                          name="state"
                          placeholder=""
                          setFieldValue={setFieldValue}
                          value={values.state}
                          options={stateList?.map(state => ({
                            value: state.id,
                            label: state.name,
                          }))}
                        />
                      </label>
                      <ErrorMessage
                        component="p"
                        className="text-red-500 mb-4 font-light text-xs"
                        name="state"
                      />
                    </div>
                  </div>
                  <div className="w-full md:w-1/2 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="city"
                      >
                        Cidade
                        <Select
                          handleBlur={handleBlur}
                          name="city"
                          placeholder=""
                          setFieldValue={setFieldValue}
                          value={values.city}
                          options={cityList}
                        />
                      </label>
                      <ErrorMessage
                        component="p"
                        className="text-red-500 mb-4 font-light text-xs"
                        name="city"
                      />
                    </div>
                  </div>
                  <div className="w-full md:w-1/2 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="company"
                      >
                        Empresa
                        <Select
                            handleBlur={handleBlur}
                            setFieldValue={setFieldValue}
                            name="company"
                            options={companies?.map((user) => ({
                              label: user.name,
                              value: user.identifier,
                            }))}
                            placeholder=""
                            value={values.company}
                            className="mt-1"
                          />
                      </label>
                      <ErrorMessage
                        component="p"
                        className="text-red-500 mb-4 font-light text-xs"
                        name="company"
                      />
                    </div>
                  </div>
                  <div className="w-full md:w-1/2 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="token"
                      >
                        Token
                        <input
                          value={values.token}
                          name="token"
                          type="text"
                          maxLength="255"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </label>
                      <ErrorMessage
                        component="p"
                        className="text-red-500 mb-4 font-light text-xs"
                        name="token"
                      />
                    </div>
                  </div>
                </div>
                <hr className="my-2 border-b-1 border-blueGray-300" />
                <div className="flex flex-wrap">
                  <div className="w-full px-4">
                    <button
                      className="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mb-2 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => {
                        if (!values.streetName || !values.streetNumber || !values.streetDistrict || !values.city || !values.state || !values.streetCode) {
                          setLocalizationError('Endereço incompleto');
                          setTimeout(() => {
                            setLocalizationError('');
                          }, 3000);
                          return;
                        }
                        setIsLoadingGeolocation(true);
                        var endereco = `${values.streetName}, ${values.streetNumber} - ${values.streetDistrict}, ${values.city.label} - ${values.state.label}, ${values.streetCode}`
                        api.get('geolocation/latlng?address=' + encodeURI(endereco))
                        .then(res => {
                          setFieldValue('latitude', res.data.lat);
                          setFieldValue('longitude', res.data.lng);
                        })
                        .finally(() => {
                          setIsLoadingGeolocation(false)
                        })
                      }}
                    >Carregar localização com base no endereço</button> <span className="text-red-500">{localizationError}</span> {isLoadingGeolocation && (<ClipLoader size={20} color="#0842ff" />)}
                  </div>
                  <div className="w-full md:w-1/2 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="latitude"
                      >
                        Latitude
                        <input
                          value={values.latitude}
                          name="latitude"
                          type="text"
                          maxLength="255"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </label>
                      <ErrorMessage
                        component="p"
                        className="text-red-500 mb-4 font-light text-xs"
                        name="latitude"
                      />
                    </div>
                  </div>
                  <div className="w-full md:w-1/2 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="longitude"
                      >
                        Longitude
                        <input
                          value={values.longitude}
                          name="longitude"
                          type="text"
                          maxLength="255"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </label>
                      <ErrorMessage
                        component="p"
                        className="text-red-500 mb-4 font-light text-xs"
                        name="longitude"
                      />
                    </div>
                  </div>
                </div>
                <hr className="my-2 border-b-1 border-blueGray-300" />
                <div className="flex justify-end w-full mt-2">
                  <button
                    disabled={isSubmitting}
                    className="bg-black text-white text-sm font-bold p-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
                    type="submit"
                  >
                    {isSubmitting ? (
                      <ClipLoader color="#fff" />
                    ) : (
                      <>{storeIdentifier ? 'SALVAR ALTERAÇÕES' : 'ADICIONAR'}</>
                    )}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        )}
      </main>
    </>
  );
}

export default ModalForm;
