import React, { useEffect, useState } from 'react';
import { Formik, ErrorMessage } from 'formik';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';

import useGet from 'services/hooks/useGet';
import api from 'services/api';
import Select from 'components/Forms/Select';
import { useProfile } from 'services/profile/getProfile';
import ReactTooltip from 'react-tooltip';

function ModalUpload({
  closeModal,
  onSuccess = () => {},
  identifier: companyIdentifier,
}) {
  const [file, setFile] = useState()
  const [isSubmitting, setIsSubmitting] = useState()

  const handleChange = (event) => {
    setFile(event.target.files[0])

  };

  const handleSubmit = (event) => {
    event.preventDefault();

    setIsSubmitting(true);

    const formData = new FormData();
    formData.append('file_upload', file);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    api
      .post(`/adm/company/${companyIdentifier}/upload`, formData, config)
      .then(() => {
        toast.success(
          `Upload feito com sucesso`
        );
        onSuccess();
        closeModal();
      })
      .finally(() => {
        setIsSubmitting(false);
      })
    ;
  };

  return (
    <>
      <main>
        <ReactTooltip />
        {(!companyIdentifier || (companyIdentifier)) && (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <div className="flex flex-wrap">
              <div className="w-full md:w-1/2 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="name"
                  >
                    Arquivo
                  </label>
                  <input type="file"  onChange={handleChange} />
                </div>
              </div>
            </div>
            <hr className="my-2 border-b-1 border-blueGray-300" />
            <div className="flex justify-end w-full mt-2">
              <button
                disabled={isSubmitting}
                className="bg-black text-white text-sm font-bold p-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
                type="submit"
              >
                {isSubmitting ? (
                  <ClipLoader color="#fff" />
                ) : (
                  <>Enviar arquivo</>
                )}
              </button>
            </div>
          </form>
        )}
      </main>
    </>
  );
}

export default ModalUpload;
