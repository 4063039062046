import React, { useEffect, useState } from 'react';
import { Formik, ErrorMessage } from 'formik';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';

import useGet from 'services/hooks/useGet';
import api from 'services/api';
import { schema } from './schema';
import Select from 'components/Forms/Select';
import { useProfile } from 'services/profile/getProfile';
import ReactTooltip from 'react-tooltip';

function ModalForm({
  closeModal,
  onSuccess = () => {},
  identifier: companyIdentifier,
}) {
  const { user } = useProfile();
  const { data: company } = useGet({
    route: `/adm/company/${companyIdentifier}/show`,
  });

  const onSubmit = ({ values, setSubmitting }) => {
    const path = companyIdentifier
      ? `adm/company/${companyIdentifier}/edit`
      : `adm/company/new`;

    api
      .post(path, values)
      .then(() => {
        toast.success(
          `Empresa ${companyIdentifier ? 'editado' : 'criado'} com sucesso`
        );
        onSuccess();
        closeModal();
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      <main>
        <ReactTooltip />
        {(!companyIdentifier || (companyIdentifier && !!company)) && (
          <Formik
            initialValues={{
              name: company?.name || '',
              description: company?.description || '',
            }}
            validationSchema={schema}
            onSubmit={(values, { setSubmitting }) => {
              onSubmit({ values, setSubmitting });
            }}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <form autoComplete="off" onSubmit={handleSubmit}>
                <div className="flex flex-wrap">
                  <div className="w-full md:w-1/2 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="name"
                      >
                        Título
                        <input
                          value={values.name}
                          name="name"
                          type="text"
                          maxLength="255"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </label>
                      <ErrorMessage
                        component="p"
                        className="text-red-500 mb-4 font-light text-xs"
                        name="name"
                      />
                    </div>
                  </div>
                  <div className="w-full md:w-1/2 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="description"
                      >
                        Descrição
                        <input
                          value={values.description}
                          name="description"
                          type="text"
                          maxLength="255"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </label>
                      <ErrorMessage
                        component="p"
                        className="text-red-500 mb-4 font-light text-xs"
                        name="description"
                      />
                    </div>
                  </div>
                </div>
                <hr className="my-2 border-b-1 border-blueGray-300" />
                <div className="flex justify-end w-full mt-2">
                  <button
                    disabled={isSubmitting}
                    className="bg-black text-white text-sm font-bold p-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
                    type="submit"
                  >
                    {isSubmitting ? (
                      <ClipLoader color="#fff" />
                    ) : (
                      <>{companyIdentifier ? 'SALVAR ALTERAÇÕES' : 'ADICIONAR'}</>
                    )}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        )}
      </main>
    </>
  );
}

export default ModalForm;
