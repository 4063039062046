import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Formik, ErrorMessage } from 'formik';
import { ClipLoader } from 'react-spinners';
import GomusLogo from '../../../assets/img/logo.png';
import { signInRequest } from 'store/modules/auth/actions';
import { initialValues, schema } from './formConfig';
import OAuth2Login from 'react-simple-oauth2-login';
import { FiArrowLeft, FiArrowUpLeft, FiSkipBack } from 'react-icons/fi';
import { FaFacebook, FaSpotify } from 'react-icons/fa';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import pt from "date-fns/locale/pt";
import Select from 'react-select';
import * as yup from 'yup';
import moment from "moment";
import api from 'services/api';
import { toast } from 'react-toastify';

registerLocale("pt", pt);

export default function Login() {
  const { slug } = useParams();

  if (slug && slug !== 'null') { // string null mesmo
    localStorage.setItem("companySlug", slug);
  }

  const dispatch = useDispatch();
  const { isLoading, responseError } = useSelector((state) => state.auth);
  const siteKey = process.env.REACT_APP_SITE_KEY;
  const [mode, setMode] = useState('Login');
  const [errorBirthdate, setErrorBirthdate] = useState(false);
  const [errorGender, setErrorGender] = useState(false);

  return (
    <>
      {slug && slug.length > 0 ? (
        <img src={`${process.env.REACT_APP_BASE_URL}/company/${slug}/image`} style={{padding: '20px', margin: 'auto', marginBottom: '30px', maxHeight: '125px'}} />
      ) : (
        <img src={GomusLogo} style={{padding: '20px', margin: 'auto', marginBottom: '30px'}} />
      )}
      <h1 className="text-center font-bold text-2xl text-black mb-4">Seja o DJ da loja!</h1>
      <div className="text-center">
        <div className="w-6/12 p-4 inline-block">
          {mode === 'Login' && (
            <Formik
              initialValues={initialValues}
              validationSchema={schema}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                dispatch(signInRequest(values));
              }}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit} className="w-full inline-block text-left">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-600 text-xs font-bold mb-2"
                      htmlFor="username"
                    >
                      E-mail
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="text"
                        name="username"
                        className="border-0 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Digite seu e-mail"
                      />
                    </label>
                    <ErrorMessage
                      component="p"
                      className="text-red mb-4 font-light text-xs"
                      name="username"
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Senha
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="password"
                        type="password"
                        className="border-0 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Password"
                      />
                    </label>
                    <ErrorMessage
                      component="p"
                      className="text-red mb-4 font-light text-xs"
                      name="password"
                    />
                  </div>

                  <div className="text-center mt-6">
                    <button
                      disabled={isLoading}
                      className="bg-black text-white text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="submit"
                    >
                      {isLoading ? (
                        <ClipLoader size={12} color="white" />
                      ) : (
                        'Entrar'
                      )}
                    </button>
                  </div>
                  <p className="text-center mt-4">
                    Ainda não tem cadastro?<br />
                    <span className="font-semibold text-sm block py-1 px-3 hover:text-blueGray-500" onClick={() => setMode('Cadastro')}>
                      <a className="font-semibold" href="#">Clique aqui e cadastre-se</a>
                    </span>
                  </p>
                </form>
              )}
            </Formik>
          )}
          {mode === 'Cadastro' && (
            <Formik
              enableReinitialize
              initialValues={{
                gender: '',
                birthdate: '',
                email: '',
                password: '',
              }}
              validationSchema={yup.object().shape({
                email: yup.string().required('O campo é obrigatório'),
                password: yup
                  .string()
                  .min(8, 'Mínimo 8 caracteres.')
                  .required('O campo é obrigatório'),
              })}
              onSubmit={(values, { setSubmitting }) => {
                if (!values.birthdate) {
                  setSubmitting(false);
                  setErrorBirthdate(true);
                  return;
                }

                if (!values.gender) {
                  setSubmitting(false);
                  setErrorGender(true);
                  return;
                }

                var aux = { ...values };

                aux = {
                  ...aux,
                  birthdate: moment(values.birthdate).format("YYYY-MM-DD"),
                  gender: values.gender?.value,
                };

                api
                  .post('api/client/register', aux)
                  .then((e) => {
                    toast.success(
                      `Cadastro efetuado com sucesso!`
                    );
                    dispatch(signInRequest({ username: aux.email, password: aux.password }));

                    setSubmitting(false);
                  })
                  .catch((item) => {
                  })

              }}
            >
              {({
                handleChange,
                handleBlur,
                setFieldValue,
                handleSubmit,
                values,
              }) => (
                <form onSubmit={handleSubmit} className="w-full inline-block text-left">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-600 text-xs font-bold mb-2"
                      htmlFor="name"
                    >
                      Nome
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="text"
                        name="name"
                        className="border-0 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Digite seu nome"
                      />
                    </label>
                    <ErrorMessage
                      component="p"
                      className="text-red mb-4 font-light text-xs"
                      name="name"
                    />
                  </div>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-600 text-xs font-bold mb-2"
                      htmlFor="email"
                    >
                      E-mail
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="text"
                        name="email"
                        className="border-0 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Digite seu e-mail"
                      />
                    </label>
                    <ErrorMessage
                      component="p"
                      className="text-red mb-4 font-light text-xs"
                      name="email"
                    />
                  </div>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-600 text-xs font-bold mb-2"
                      htmlFor="gender"
                    >
                      Gênero
                      <Select
                        handleBlur={handleBlur}
                        name="gender"
                        placeholder="Selecione o seu gênero"
                        setFieldValue={setFieldValue}
                        value={values.gender}
                        isClearable
                        onChange={option => {
                            setFieldValue("gender", option);
                            setErrorGender(false);
                        }}
                        options={[
                          { value: 'MASCULINO', label: 'MASCULINO' },
                          { value: 'FEMININO', label: 'FEMININO' },
                          { value: 'OUTROS', label: 'OUTROS' },
                          { value: 'PREFIRO_NAO_INFORMAR', label: 'PREFIRO NÃO INFORMAR' },
                        ]}
                      />
                    </label>
                    {errorGender && (
                      <span
                        component="p"
                        className="text-red mb-4 font-light text-xs"
                        name="gender"
                      >
                        O campo obrigatório.
                      </span>
                    )}
                  </div>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-600 text-xs font-bold mb-2"
                      htmlFor="birthdate"
                    >
                      Data de nascimento
                      <DatePicker
                        locale="pt"
                        placeholderText="Preencha a data de nascimento"
                        selected={values.birthdate && new Date(values.birthdate)}
                        dateFormat="dd/MM/yyyy"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        name="birthdate"
                        onChange={date => {
                          setFieldValue("birthdate", date);
                          setErrorBirthdate(false);
                        }}
                      />
                    </label>
                    {errorBirthdate && (
                      <span
                        component="p"
                        className="text-red mb-4 font-light text-xs"
                        name="birthdate"
                      >
                        O campo obrigatório.
                      </span>
                    )}
                  </div>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Senha
                      <input
                        placeholder="Digite sua senha"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="password"
                        type="password"
                        className="border-0 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Digite sua senha"
                      />
                    </label>
                    <ErrorMessage
                      component="p"
                      className="text-red mb-4 font-light text-xs"
                      name="password"
                    />
                  </div>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-600 text-xs font-bold mb-2"
                      htmlFor="confirmPassword"
                    >
                      Confirme sua senha
                      <input
                        placeholder="Digite sua senha"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="confirmPassword"
                        type="password"
                        className="border-0 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Confirme sua senha"
                      />
                    </label>
                    <ErrorMessage
                      component="p"
                      className="text-red mb-4 font-light text-xs"
                      name="confirmPassword"
                    />
                  </div>
                  <div className="text-center mt-6">
                    <button
                      disabled={isLoading}
                      className="bg-black text-white text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="submit"
                    >
                      {isLoading ? (
                        <ClipLoader size={12} color="white" />
                      ) : (
                        mode === "Cadastro" ? "Cadastrar" : 'Entrar'
                      )}
                    </button>
                  </div>
                  <button
                    className="mt-5 bg-gray-500 text-white text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {setMode('Login')}}
                  >
                    Voltar
                  </button>
                  <p className="text-center mt-4">
                    Já tem cadastro?<br />
                    <span className="font-semibold text-sm block py-1 px-3 hover:text-blueGray-500" onClick={() => setMode('Login')}>
                      <a className="font-semibold" href="#">Entrar</a>
                    </span>
                  </p>
                </form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </>
  );
}
