import React from 'react';
import { Link, useLocation } from 'react-router-dom';

export default function AdminOptions() {
  const { pathname, search } = useLocation();
  const route = pathname + search;

  return (
    <>
      <h6 className="md:min-w-full text-gray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
        Marcas
      </h6>

      <ul className="md:flex-col md:min-w-full flex flex-col list-none">
        <li className="items-center">
          <Link
            className={`text-xs uppercase py-3 font-bold block ${
              route === '/admin/company'
                ? 'text-black'
                : 'text-gray-700 hover:text-gray-500'
            }`}
            to="/admin/company"
          >
            <i
              className={`fas fa-list mr-2 text-sm ${
                route === '/admin/company'
                  ? 'opacity-75'
                  : 'text-gray-300'
              }`}
            />{' '}
            Listar
          </Link>
        </li>
      </ul>

      <hr className="my-4 border-b-1 border-gray-300" />

      <h6 className="md:min-w-full text-gray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
        Lojas / Pontos
      </h6>

      <ul className="md:flex-col md:min-w-full flex flex-col list-none">
        <li className="items-center">
          <Link
            className={`text-xs uppercase py-3 font-bold block ${
              route === '/admin/store'
                ? 'text-black'
                : 'text-gray-700 hover:text-gray-500'
            }`}
            to="/admin/store"
          >
            <i
              className={`fas fa-list mr-2 text-sm ${
                route === '/admin/store'
                  ? 'opacity-75'
                  : 'text-gray-300'
              }`}
            />{' '}
            Listar
          </Link>
        </li>
        <li className="items-center">
          <Link
            className={`text-xs uppercase py-3 font-bold block ${
              route === '/admin/users/player'
                ? 'text-black'
                : 'text-gray-700 hover:text-gray-500'
            }`}
            to="/admin/users/player"
          >
            <i
              className={`fas fa-user mr-2 text-sm ${
                route === '/admin/users/player'
                  ? 'opacity-75'
                  : 'text-gray-300'
              }`}
            />{' '}
            Usuários de players
          </Link>
        </li>
      </ul>

      <hr className="my-4 border-b-1 border-gray-300" />

      <h6 className="md:min-w-full text-gray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
        Usuários
      </h6>

      <ul className="md:flex-col md:min-w-full flex flex-col list-none">
        <li className="items-center">
          <Link
            className={`text-xs uppercase py-3 font-bold block ${
              route === '/admin/users/admin'
                ? 'text-black'
                : 'text-gray-700 hover:text-gray-500'
            }`}
            to="/admin/users/admin"
          >
            <i
              className={`fas fa-user mr-2 text-sm ${
                route === '/admin/users/admin'
                  ? 'opacity-75'
                  : 'text-gray-300'
              }`}
            />{' '}
            Administradores
          </Link>
        </li>
        <li className="items-center">
          <Link
            className={`text-xs uppercase py-3 font-bold block ${
              route === '/admin/users/client'
                ? 'text-black'
                : 'text-gray-700 hover:text-gray-500'
            }`}
            to="/admin/users/client"
          >
            <i
              className={`fas fa-user mr-2 text-sm ${
                route === '/admin/users/client'
                  ? 'opacity-75'
                  : 'text-gray-300'
              }`}
            />{' '}
            Clientes
          </Link>
        </li>
      </ul>

      <hr className="my-4 border-b-1 border-gray-300" />

      <ul className="md:flex-col md:min-w-full flex flex-col list-none">
        <li className="items-center">
          <Link
            className={`text-xs uppercase py-3 font-bold block ${
              route === '/admin/feedback'
                ? 'text-black'
                : 'text-gray-700 hover:text-gray-500'
            }`}
            to="/admin/feedback"
          >
            <i
              className={`fas fa-thumbs-up mr-2 text-sm ${
                route === '/admin/feedback'
                  ? 'opacity-75'
                  : 'text-gray-300'
              }`}
            />{' '}
            Feedbacks
          </Link>
        </li>
      </ul>
    </>
  );
}
