import React from 'react';
import { FaInstagramSquare, FaSpotify } from 'react-icons/fa';
import { FiInstagram } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import LogoFooter from '../../assets/img/logo-black.png';
import { Logout } from '../../services/api';

const authInfoRaw = localStorage.getItem("persist:gomus");
const authInfo = authInfoRaw && authInfoRaw.length > 0 ? JSON.parse(authInfoRaw) : null;
const auth = authInfo && authInfo.auth && authInfo.auth.length > 0 ? JSON.parse(authInfo.auth) : null;
const token = auth?.token;

export default function FooterSmall({ absolute }) {
  return (
    <>
      <footer
        className={`${
          absolute ? 'absolute w-full bottom-0 bg-black' : 'relative'
        } pb-6 z-30`}
      >
        <div className="container mx-auto px-4">
          <div className="flex mt-6 flex-wrap items-center md:justify-between justify-center">
            <div className="w-full md:w-4/12 px-4">
              <div className="text-sm text-white py-1 pb-2 text-center md:text-left">
                <Link to="/public/terms">Termos de uso e políticas de privacidade</Link>
              </div>
              <div className="text-sm text-white font-semibold py-1 text-center md:text-left">
                Siga a gente <a href="https://www.instagram.com/gomusmusic/" target="_blank"><FiInstagram size="25px" className="inline" /></a> <a href="https://open.spotify.com/user/gomusmusic" target="_blank"><FaSpotify size="25px" className="inline" /> <img src={LogoFooter} className="inline" /></a>
              </div>
            </div>
            {token && token.length > 0 && <div className="w-full md:w-4/12 px-4">
              <div className="text-sm text-white py-1 pb-2 text-center md:text-right">
                <button className="cursor-pointer" onClick={() => Logout()}>Sair</button>
              </div>
            </div>}
          </div>
        </div>
      </footer>
    </>
  );
}
