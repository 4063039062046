import React, { useState, useEffect } from 'react';
import { FiRefreshCw } from 'react-icons/fi';
import { FaThumbsDown, FaThumbsUp } from 'react-icons/fa';
import useDelete from 'services/hooks/useDelete';
import { useSelector } from 'react-redux';
import useGetList from 'services/hooks/useGetList';
import SearchEngine from 'components/Search/SearchEngine';
import TablePaginator from 'components/Paginator';
import PageCard from 'components/Cards/PageCard';
import Sorting from "components/Sorting";
import { useProfile } from 'services/profile/getProfile';
import { Container } from '../../../assets/shared/styles';
import CardTableList from 'components/Cards/CardTableList';

const Store = ({ color = 'light' }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const search = useSelector((state) => state.filter.value);
  const { user } = useProfile();
  const [sortingString, setSortingString] = useState("");
  const [currentSortingField, setCurrentSortingField] = useState("");
  const [currentSortingDirection, setCurrentSortingDirection] = useState(false);

  // get LIST
  const {
    data: feedbacks,
    getItems,
    isLoading: isLoadingList,
    meta,
  } = useGetList({
    page: currentPage,
    route: `adm/feedback/list?&${sortingString}`,
    search,
    sortingString,
  });

  const isLoading = isLoadingList;

  useEffect(() => {
    getItems();
}, [sortingString]);

  return (
    <>
      <PageCard
        color={color}
        headerContent={
          <>
            <SearchEngine role={user.role} fields={['search']} />
          </>
        }
      >
        <CardTableList title="Feedbacks" color={color}>
          <div className="relative mb-2">
            <div className="absolute bottom-0 right-0">
              <button
                type="button"
                title="Atualizar listagem"
                className="p-2 mr-3 auto rounded bg-black"
                onClick={getItems}
              >
                <FiRefreshCw size={15} color="#fff" />
              </button>
            </div>
          </div>
          <Container>
            <div className="overflow-auto">
              <table className="items-center table-auto w-full bg-transparent border-collapse">
                <thead>
                  <tr>
                    <th
                      className={`align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ${
                        color === 'light'
                          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                          : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700'
                      }`}
                    >
                      <Sorting
                        label="Cliente"
                        field="user.name"
                        currentSortingField={currentSortingField}
                        setCurrentSortingField={setCurrentSortingField}
                        currentSortingDirection={currentSortingDirection}
                        setCurrentSortingDirection={setCurrentSortingDirection}
                        setSortingString={setSortingString}
                      />
                    </th>
                    <th
                      className={`align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ${
                        color === 'light'
                          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                          : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700'
                      }`}
                    >
                      <Sorting
                        label="Loja"
                        field="store.name"
                        currentSortingField={currentSortingField}
                        setCurrentSortingField={setCurrentSortingField}
                        currentSortingDirection={currentSortingDirection}
                        setCurrentSortingDirection={setCurrentSortingDirection}
                        setSortingString={setSortingString}
                      />
                    </th>
                    <th
                      className={`align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ${
                        color === 'light'
                          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                          : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700'
                      }`}
                    >
                      <Sorting
                        label="Feedback"
                        field="e.feedback"
                        currentSortingField={currentSortingField}
                        setCurrentSortingField={setCurrentSortingField}
                        currentSortingDirection={currentSortingDirection}
                        setCurrentSortingDirection={setCurrentSortingDirection}
                        setSortingString={setSortingString}
                      />
                    </th>
                    <th
                      className={`align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ${
                        color === 'light'
                          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                          : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700'
                      }`}
                    >
                      <Sorting
                        label="Comentario"
                        field="e.description"
                        currentSortingField={currentSortingField}
                        setCurrentSortingField={setCurrentSortingField}
                        currentSortingDirection={currentSortingDirection}
                        setCurrentSortingDirection={setCurrentSortingDirection}
                        setSortingString={setSortingString}
                      />
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {feedbacks?.map((feedbackEntity) => {
                    const { id, user, store, feedback, description } = feedbackEntity;

                    return (
                      <>
                        <tr key={id}>
                          <td className="text-xs whitespace-nowrap">
                            <span
                              className={`font-bold ${+(color === 'light'
                                ? 'text-blueGray-600'
                                : 'text-white')}`}
                            >
                              {user.name}
                            </span>
                          </td>
                          <td className="text-xs font-light whitespace-nowrap">
                            <span
                              className={`${+(color === 'light'
                                ? 'text-blueGray-600'
                                : 'text-white')}`}
                            >
                              {store.name}
                            </span>
                          </td>
                          <td className="text-xs font-light whitespace-nowrap">
                            <span
                              className={`${+(color === 'light'
                                ? 'text-blueGray-600'
                                : 'text-white')}`}
                            >
                              {feedback == 'POSITIVE' ? <FaThumbsUp /> : <FaThumbsDown />}
                            </span>
                          </td>
                          <td className="text-xs font-light whitespace-nowrap">
                            <span
                              className={`${+(color === 'light'
                                ? 'text-blueGray-600'
                                : 'text-white')}`}
                            >
                              {description}
                            </span>
                          </td>
                        </tr>
                      </>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </Container>
        </CardTableList>
        <TablePaginator
          data={feedbacks}
          emptyMessage="Nenhum feedback encontrado"
          isLoading={isLoading}
          meta={meta}
          setCurrentPage={setCurrentPage}
        />
      </PageCard>
    </>
  );
};

export default Store;
