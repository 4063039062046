import React from 'react';

export default function FooterAdmin() {
  return (
    <>
      <footer className="block py-4">
        <div className="container mx-auto px-4">
          <hr className="mb-4 border-b-1 border-blueGray-200" />
          <div className="flex flex-wrap items-center justify-end">
            <div className="text-sm text-blueGray-500">
              <span className="text-sm block py-1 px-3">
                Made by <a className="font-semibold" href="https://gomus.com.br" target="_blank">gomus</a> {'&'} powered by <a className="font-semibold" href="https://topnode.com.br" target="_blank">top(node)</a>
              </span>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
