import { createGlobalStyle } from 'styled-components';
import theme from 'assets/styles/theme';

const GlobalStyle = createGlobalStyle`

@font-face {
  font-family: 'TT Commons';
  src: url(/assets/fonts/TTCommons-Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TT Commons';
  src: url(/assets/fonts/TTCommons-Bold.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
}

* {
  margin:0;
  padding:0;
  box-sizing:border-box;
  font-family: 'TT Commons';

  }
  body {
    background: ${theme.background};
    color: ${theme.text};
    -webkit-font-smoothing: antialiased;
  }
  body, input, button {
    font-size: 16px;
  }
  h1, h2, h3, h4, h5 {
    font-weight:100;
  }
  button {
    cursor: pointer;
  }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.popupdiv {
  visibility: hidden;
}
.popuptext {
  width: 300px;
  background-color: #FFF;
  color: #555;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
}

.popuptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #FFF transparent transparent transparent;
}

.popupdiv.show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s
}

`;
export default GlobalStyle;
