import React, { useEffect, useState } from 'react';
import api from 'services/api';
import GomusLogo from '../../../assets/img/logo.png';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';

export default function PublicPolicy() {
  return (
    <>
      <Link to="/">
        <img src={GomusLogo} style={{padding: '20px', margin: 'auto', marginBottom: '30px'}} />
      </Link>
      <h1 className="text-center font-bold text-2xl text-black mb-4">
        Termos de uso e políticas de privacidade.
      </h1>
      <div style={{padding: '40px'}}>
          <p className="max-h-64 overflow-auto m-4"></p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "right",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Política de Privacidade GOMUS JUKEBOX v. 01
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              POLÍTICA DE PRIVACIDADE{" "}
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Esta Política de Privacidade serve para informar os usuários de como seus
              dados e informações estão sendo tratados e como a GOMUS JUKEBOX lida com
              eles.&nbsp;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Este tipo de informação é muito importante para nós e para você, usuário,
              que está confiando as suas informações aos cuidados da GOMUS JUKEBOX.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Garantimos que terá todos os seus direitos resguardados pela legislação
              brasileira, em especial da Lei Federal 13.709/18 (Lei Geral de Proteção de
              Dados).
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Para melhor tratá-lo vamos precisar recolher algumas informações, e por
              isto, esta Política de Privacidade deve ser lida integralmente.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Ao final deste documento consta relação de definições dos termos tratados
              nesta política e forma de contato.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Tratamento de dados&nbsp;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Para acesso ao site da GOMUS JUKEBOX, não é necessário o fornecimento de
              qualquer dado pessoal, porém, em razão do tipo de serviço prestado, bem
              como para melhor atendimento e segurança, a GOMUS JUKEBOX irá solicitar
              informações pessoais, tais como nome, senha, gênero, e-mail, data de nascimento
              e as manterá em Banco de Dados seguro. Conforme tabela com descritivo do dado coletado e o motivo:
            </span>
          </p>
          <div align="left" dir="ltr" style={{ marginLeft: "0.75pt" }}>
            <table style={{ border: "none", borderCollapse: "collapse" }}>
              <tbody>
                <tr style={{ height: "22pt" }}>
                  <td
                    style={{
                      borderLeft: "solid #000000 1pt",
                      borderRight: "solid #000000 1pt",
                      borderBottom: "solid #000000 1pt",
                      borderTop: "solid #000000 1pt",
                      verticalAlign: "top",
                      padding: "5pt 5pt 5pt 5pt",
                      overflow: "hidden",
                      overflowWrap: "break-word"
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.2",
                        marginTop: "0pt",
                        marginBottom: "0pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "12pt",
                          fontFamily: "Calibri,sans-serif",
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        Dado
                      </span>
                    </p>
                  </td>
                  <td
                    colSpan={2}
                    style={{
                      borderLeft: "solid #000000 1pt",
                      borderRight: "solid #000000 1pt",
                      borderBottom: "solid #000000 1pt",
                      borderTop: "solid #000000 1pt",
                      verticalAlign: "top",
                      padding: "5pt 5pt 5pt 5pt",
                      overflow: "hidden",
                      overflowWrap: "break-word"
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.2",
                        marginTop: "0pt",
                        marginBottom: "0pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "12pt",
                          fontFamily: "Calibri,sans-serif",
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        Razão da coleta
                      </span>
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "22pt" }}>
                  <td
                    style={{
                      borderLeft: "solid #000000 1pt",
                      borderRight: "solid #000000 1pt",
                      borderBottom: "solid #000000 1pt",
                      borderTop: "solid #000000 1pt",
                      verticalAlign: "top",
                      padding: "5pt 5pt 5pt 5pt",
                      overflow: "hidden",
                      overflowWrap: "break-word"
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.2",
                        marginTop: "0pt",
                        marginBottom: "0pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "12pt",
                          fontFamily: "Calibri,sans-serif",
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        IP de conexão
                      </span>
                    </p>
                  </td>
                  <td
                    colSpan={2}
                    style={{
                      borderLeft: "solid #000000 1pt",
                      borderRight: "solid #000000 1pt",
                      borderBottom: "solid #000000 1pt",
                      borderTop: "solid #000000 1pt",
                      verticalAlign: "top",
                      padding: "5pt 5pt 5pt 5pt",
                      overflow: "hidden",
                      overflowWrap: "break-word"
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.2",
                        marginTop: "0pt",
                        marginBottom: "0pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "12pt",
                          fontFamily: "Calibri,sans-serif",
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        Validação do acesso sendo feito pelo usuário, para evitar acesso
                        indevido.
                      </span>
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "22pt" }}>
                  <td
                    style={{
                      borderLeft: "solid #000000 1pt",
                      borderRight: "solid #000000 1pt",
                      borderBottom: "solid #000000 1pt",
                      borderTop: "solid #000000 1pt",
                      verticalAlign: "top",
                      padding: "5pt 5pt 5pt 5pt",
                      overflow: "hidden",
                      overflowWrap: "break-word"
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.2",
                        marginTop: "0pt",
                        marginBottom: "0pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "12pt",
                          fontFamily: "Calibri,sans-serif",
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        Nome completo
                      </span>
                    </p>
                  </td>
                  <td
                    colSpan={2}
                    style={{
                      borderLeft: "solid #000000 1pt",
                      borderRight: "solid #000000 1pt",
                      borderBottom: "solid #000000 1pt",
                      borderTop: "solid #000000 1pt",
                      verticalAlign: "top",
                      padding: "5pt 5pt 5pt 5pt",
                      overflow: "hidden",
                      overflowWrap: "break-word"
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.2",
                        marginTop: "0pt",
                        marginBottom: "0pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "12pt",
                          fontFamily: "Calibri,sans-serif",
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        Identificação do usuário para tratamento pessoal e registro.
                      </span>
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "22pt" }}>
                  <td
                    style={{
                      borderLeft: "solid #000000 1pt",
                      borderRight: "solid #000000 1pt",
                      borderBottom: "solid #000000 1pt",
                      borderTop: "solid #000000 1pt",
                      verticalAlign: "top",
                      padding: "5pt 5pt 5pt 5pt",
                      overflow: "hidden",
                      overflowWrap: "break-word"
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.2",
                        marginTop: "0pt",
                        marginBottom: "0pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "12pt",
                          fontFamily: "Calibri,sans-serif",
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        Endereço de e-mail - Autenticação
                      </span>
                    </p>
                  </td>
                  <td
                    colSpan={2}
                    style={{
                      borderLeft: "solid #000000 1pt",
                      borderRight: "solid #000000 1pt",
                      borderBottom: "solid #000000 1pt",
                      borderTop: "solid #000000 1pt",
                      verticalAlign: "top",
                      padding: "5pt 5pt 5pt 5pt",
                      overflow: "hidden",
                      overflowWrap: "break-word"
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.2",
                        marginTop: "0pt",
                        marginBottom: "0pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "12pt",
                          fontFamily: "Calibri,sans-serif",
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        Identificação do usuário, forma de contato e campo de
                        identificador para autenticação na plataforma.
                      </span>
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "22pt" }}>
                  <td
                    style={{
                      borderLeft: "solid #000000 1pt",
                      borderRight: "solid #000000 1pt",
                      borderBottom: "solid #000000 1pt",
                      borderTop: "solid #000000 1pt",
                      verticalAlign: "top",
                      padding: "5pt 5pt 5pt 5pt",
                      overflow: "hidden",
                      overflowWrap: "break-word"
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.2",
                        marginTop: "0pt",
                        marginBottom: "0pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "12pt",
                          fontFamily: "Calibri,sans-serif",
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        Data de nascimento
                      </span>
                    </p>
                  </td>
                  <td
                    colSpan={2}
                    style={{
                      borderLeft: "solid #000000 1pt",
                      borderRight: "solid #000000 1pt",
                      borderBottom: "solid #000000 1pt",
                      borderTop: "solid #000000 1pt",
                      verticalAlign: "top",
                      padding: "5pt 5pt 5pt 5pt",
                      overflow: "hidden",
                      overflowWrap: "break-word"
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.2",
                        marginTop: "0pt",
                        marginBottom: "0pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "12pt",
                          fontFamily: "Calibri,sans-serif",
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        Identificação do usuário e validação para gradação etária. Caso
                        o usuário seja menor de idade, será solicitado o cadastro de um
                        maior responsável.
                      </span>
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "22pt" }}>
                  <td
                    style={{
                      borderLeft: "solid #000000 1pt",
                      borderRight: "solid #000000 1pt",
                      borderBottom: "solid #000000 1pt",
                      borderTop: "solid #000000 1pt",
                      verticalAlign: "top",
                      padding: "5pt 5pt 5pt 5pt",
                      overflow: "hidden",
                      overflowWrap: "break-word"
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.2",
                        marginTop: "0pt",
                        marginBottom: "0pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "12pt",
                          fontFamily: "Calibri,sans-serif",
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        Gênero
                      </span>
                    </p>
                  </td>
                  <td
                    colSpan={2}
                    style={{
                      borderLeft: "solid #000000 1pt",
                      borderRight: "solid #000000 1pt",
                      borderBottom: "solid #000000 1pt",
                      borderTop: "solid #000000 1pt",
                      verticalAlign: "top",
                      padding: "5pt 5pt 5pt 5pt",
                      overflow: "hidden",
                      overflowWrap: "break-word"
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.2",
                        marginTop: "0pt",
                        marginBottom: "0pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "12pt",
                          fontFamily: "Calibri,sans-serif",
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        Compreensão melhor do perfil do usuário acessando o sistema.
                      </span>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Desta forma, para acesso às plataformas e aos serviços da GOMUS JUKEBOX,
              será necessário o fornecimento de informações pessoais para criação de
              usuário.&nbsp;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              As informações podem ser transferidas de outras plataformas, previamente
              disponíveis, caso o usuário dê consentimento.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Todos os dados que a GOMUS JUKEBOX tiver contato serão protegidos com
              rigor técnico, para salvaguardar os direitos dos usuários.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              A GOMUS JUKEBOX solicita apenas dados necessários para execução dos
              serviços prestados. Informações além dos serviços prestados não devem ser
              compartilhadas com a GOMUS JUKEBOX, que se responsabiliza apenas no
              tratamento dos dados do usuário.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Informações incorretas ou irregulares, por qualquer usuário, irão afetar
              diretamente a metodologia de interação do usuário com a plataforma GOMUS
              JUKEBOX, podendo prejudicar a interação e a experiência dos serviços.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Uma vez que o perfil do usuário esteja criado, todos os dados pessoais
              poderão ser acessados, alterados ou corrigidos, conforme a preferência do
              usuário, a qualquer tempo, ressalvados os momentos que as plataformas
              estejam suspensas por manutenção técnica.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              A GOMUS JUKEBOX coleta dados pessoais quando é realizado o registro da
              conta do usuário e poderá usá-los para anúncios que o usuário pode
              considerar útil, preferências, e-mail, marketing, direcionamento de
              campanha, marketing offline, anúncios no Google.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              As informações pessoais ficam registradas em servidor de controle da GOMUS
              JUKEBOX ou terceirizada, denominada Operadora, que também subscreve à esta
              Política de Privacidade.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              A GOMUS JUKEBOX poderá coletar informações públicas para direcionamento de
              perfil e ajustar publicidade voltadas para o usuário.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Os dados de crianças e adolescentes, serão sempre vinculados ao
              representante legal,&nbsp;
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              vez que os serviços prestados são voltados ao público maior de 18
              (dezoito) anos.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              A GOMUS JUKEBOX sempre que encerrar o relacionamento com o usuário se
              compromete a excluir os dados ou anonimiza-los.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Direitos assegurados
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              O usuário tem todos os direitos garantidos pela legislação brasileira, em
              especial:
            </span>
          </p>
          <ul style={{ marginTop: 0, marginBottom: 0 }}>
            <li
              dir="ltr"
              style={{
                listStyleType: "disc",
                fontSize: "12pt",
                fontFamily: '"Noto Sans Symbols",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginRight: "1.8pt",
                  textAlign: "justify",
                  marginTop: "6pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Informação sobre existência de tratamento de dados;
                </span>
              </p>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: "disc",
                fontSize: "12pt",
                fontFamily: '"Noto Sans Symbols",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginRight: "1.8pt",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Acesso aos dados;
                </span>
              </p>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: "disc",
                fontSize: "12pt",
                fontFamily: '"Noto Sans Symbols",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginRight: "1.8pt",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Correção de dados incompletos, inexatos ou desatualizados;
                </span>
              </p>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: "disc",
                fontSize: "12pt",
                fontFamily: '"Noto Sans Symbols",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginRight: "1.8pt",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Anonimização, bloqueio ou eliminação de dados desnecessários,
                  excessivos ou tratados em desconformidade com o disposto na Lei;
                </span>
              </p>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: "disc",
                fontSize: "12pt",
                fontFamily: '"Noto Sans Symbols",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginRight: "1.8pt",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Portabilidade dos dados a outro fornecedor de serviço ou produto,
                  mediante requisição expressa, de acordo com a regulamentação da
                  autoridade nacional, observados os segredos comercial e industrial;
                </span>
              </p>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: "disc",
                fontSize: "12pt",
                fontFamily: '"Noto Sans Symbols",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginRight: "1.8pt",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Eliminação dos dados pessoais tratados com o consentimento do titular;
                </span>
              </p>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: "disc",
                fontSize: "12pt",
                fontFamily: '"Noto Sans Symbols",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginRight: "1.8pt",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Informação das entidades públicas e privadas com as quais o
                  controlador realizou uso compartilhado de dados;
                </span>
              </p>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: "disc",
                fontSize: "12pt",
                fontFamily: '"Noto Sans Symbols",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginRight: "1.8pt",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Informação sobre a possibilidade de não fornecer consentimento e sobre
                  as consequências da negativa;
                </span>
              </p>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: "disc",
                fontSize: "12pt",
                fontFamily: '"Noto Sans Symbols",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre"
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  marginRight: "1.8pt",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt"
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Revogação do consentimento.
                </span>
              </p>
            </li>
          </ul>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Caso algum destes direitos ou quaisquer que entenda cabível não for
              executado pela GOMUS JUKEBOX, deve o usuário encaminhar e-mail para o
              endereço ti@gomus.com.br ou entrar em contato com o encarregado por
              tratamento de proteção de dados, indicado no website da GOMUS JUKEBOX.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Dados Anônimos e Tecnologia de Localização
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Uma vez que quaisquer dados, sejam pessoais ou sensíveis, estejam
              impassíveis de identificação, por meio de tecnologias adequadas, usadas
              pela GOMUS JUKEBOX, estes se tornarão dados anônimos.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              A GOMUS JUKEBOX poderá usar os dados anônimos para pesquisas, estatísticas
              e outros fins lícitos que lhe couber, sem necessidade de consentimento do
              usuário.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Quando o usuário se utiliza das plataformas da GOMUS JUKEBOX a mesma
              poderá solicitar cookies e tecnologias que permitam rastreamento para
              melhor atendimento e personalização, visando a melhor experiência do
              usuário. Esta funcionalidade não inviabiliza o uso das plataformas da
              GOMUS JUKEBOX, porém a experiência de usuário não será completa e mais
              imersiva conforme foi planejado, retirando a possibilidade de algumas
              praticidades.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Transferência e compartilhamento de dados
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              A GOMUS JUKEBOX, na qualidade de Controladora, não transfere ou
              compartilha dados pessoais ou sensíveis para entidades públicas ou
              privadas sem autorização prévia do usuário ou determinação de Autoridade
              Pública competente, com a exceção de compartilhamento com empresas
              terceirizadas Operadoras de Dados, que tem as mesmas responsabilidades da
              GOMUS JUKEBOX e atuação restringida pelas decisões realizadas pela GOMUS
              JUKEBOX, constantes nesta Política de Privacidade.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Uma vez que as informações processadas tenham o caráter pessoal ou
              sensível a GOMUS JUKEBOX fará uso apenas do necessário e com rigor no
              sigilo, seja de forma direta ou como Operadora.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              A depender da empresa que for Operadora dos dados,&nbsp;
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              pode ocorrer transferência internacional de dados
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              , contanto que a empresa tenha as mesmas políticas de privacidade que a
              GOMUS JUKEBOX ou se adeque de forma integral à Lei Geral de Proteção de
              Dados do Brasil.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Segurança
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              A GOMUS JUKEBOX se compromete a incorporar garantias de proteção a
              segurança e privacidade do usuário com o uso de formas e técnicas
              recomendadas para manter todas as informações e dados sigilosos, sejam
              pessoais ou sensíveis.&nbsp;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              A empresa utiliza apenas do necessário para experiência do usuário e
              correta aplicação dos serviços prestados.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              A GOMUS JUKEBOX usa as tecnologias de segurança que melhor se amoldam às
              demandas do mercado para proteção dos dados e informações pessoais de
              acesso. Para tanto a comunicação do site e serviços serão garantidos com
              tecnologias de criptografia, como &nbsp;
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "italic",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Secure Sockets Layer
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              &nbsp;(SSL).
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Apesar de todos os esforços em manter as informações seguras com
              tecnologias recomendadas, é importante ressaltar que nenhum sistema de
              segurança é impenetrável e as informações passadas por meio da internet
              não são totalmente seguras, por isto, enquanto a GOMUS JUKEBOX estiver
              tomando as medidas necessárias para proteção das informações pessoais, não
              existe forma de garantia plena, mas assegura que utiliza as melhores
              formas de proteção disponíveis.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Todas as medidas de segurança tomadas pela GOMUS JUKEBOX, em decorrência
              desta Política de Privacidade, têm vigência a partir da declaração do
              aceite e terminam no final do tratamento do dado pessoal do usuário,
              porém, extensão da aplicação posterior caso o dado ainda se encontre nas
              plataformas, assegurando ao usuário, sue propriedade dos dados.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Caso ocorra incidente de segurança relevante nas plataformas da GOMUS
              JUKEBOX, as Autoridades competentes, na forma da Lei, e os usuários serão
              notificados com descritivo da natureza dos dados afetados, indicações das
              medidas técnicas de segurança, riscos decorrentes da vulnerabilidade e
              quais medidas estão sendo tomadas para sanar a situação.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              &nbsp;
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Publicidade, Informativos, E-mail e Mensagens de Texto
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Diante do tipo de serviço oferecido, a GOMUS JUKEBOX, caso haja
              consentimento usuário em recebimento, poderá encaminhar material
              publicitário em formato de e-mail ou boletins, com informações ou
              promoções da empresa ou de seus parceiros comerciais a material
              publicitário de marketing.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Para cancelamento de envio de material publicitário, deve o usuário
              desabilitar o envio no aplicativo.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              A GOMUS JUKEBOX contrata empresa terceirizada que responderá de forma
              automatizada via “
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "italic",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              chatbots
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              ” para melhor atendimento das necessidades dos usuários. Após cumprida a
              etapa automatizada, o atendimento, se ainda não resolvido, poderá ser
              encaminhado para o setor responsável pela reparação do inconveniente.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Alterações nesta Política
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              A GOMUS JUKEBOX reserva-se no direito de alterar esta Política mediante
              comunicação por seu website e a qualquer momento. A alteração afeta
              diretamente qualquer dado que a GOMUS JUKEBOX tenha do usuário e a
              continuidade do uso das plataformas, pelo usuário, infere ciência e
              concordância com a presente Política.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Caso a alteração da Política seja estrutural, atualização da mesma será
              expedida para aceitação do usuário.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Definições
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              marginRight: "1.8pt",
              textAlign: "justify",
              marginTop: "6pt",
              marginBottom: "6pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Dado Pessoal: informação relacionada a pessoa natural identificada ou
              identificável;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              marginRight: "1.8pt",
              textAlign: "justify",
              marginTop: "6pt",
              marginBottom: "6pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Dado Pessoal Sensível: dado pessoal sobre origem racial ou étnica,
              convicção religiosa, opinião política, filiação a sindicato ou a
              organização de caráter religioso, filosófico ou político, dado referente à
              saúde ou à vida sexual, dado genético ou biométrico, quando vinculado a
              uma pessoa natural;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              marginRight: "1.8pt",
              textAlign: "justify",
              marginTop: "6pt",
              marginBottom: "6pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Dado Anonimizado: dado relativo a titular que não possa ser identificado,
              considerando a utilização de meios técnicos razoáveis e disponíveis na
              ocasião de seu tratamento;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              marginRight: "1.8pt",
              textAlign: "justify",
              marginTop: "6pt",
              marginBottom: "6pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Banco de Dados: conjunto estruturado de dados pessoais, estabelecido em um
              ou em vários locais, em suporte eletrônico ou físico;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              marginRight: "1.8pt",
              textAlign: "justify",
              marginTop: "6pt",
              marginBottom: "6pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Titular/usuário: pessoa natural a quem se referem os dados pessoais que
              são objeto de tratamento;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              marginRight: "1.8pt",
              textAlign: "justify",
              marginTop: "6pt",
              marginBottom: "6pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Controlador: pessoa natural ou jurídica, de direito público ou privado, a
              quem competem as decisões referentes ao tratamento de dados pessoais;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              marginRight: "1.8pt",
              textAlign: "justify",
              marginTop: "6pt",
              marginBottom: "6pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Operador: pessoa natural ou jurídica, de direito público ou privado, que
              realiza o tratamento de dados pessoais em nome do controlador;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              marginRight: "1.8pt",
              textAlign: "justify",
              marginTop: "6pt",
              marginBottom: "6pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Encarregado: pessoa indicada pelo controlador e operador para atuar como
              canal de comunicação entre o controlador, os titulares dos dados e a
              Competente;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              marginRight: "1.8pt",
              textAlign: "justify",
              marginTop: "6pt",
              marginBottom: "6pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Tratamento: toda operação realizada com dados pessoais, como as que se
              referem a coleta, produção, recepção, classificação, utilização, acesso,
              reprodução, transmissão, distribuição, processamento, arquivamento,
              armazenamento, eliminação, avaliação ou controle da informação,
              modificação, comunicação, transferência, difusão ou extração;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              marginRight: "1.8pt",
              textAlign: "justify",
              marginTop: "6pt",
              marginBottom: "6pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Anonimização: utilização de meios técnicos razoáveis e disponíveis no
              momento do tratamento, por meio dos quais um dado perde a possibilidade de
              associação, direta ou indireta, a um indivíduo;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              marginRight: "1.8pt",
              textAlign: "justify",
              marginTop: "6pt",
              marginBottom: "6pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Consentimento: manifestação livre, informada e inequívoca pela qual o
              titular concorda com o tratamento de seus dados pessoais para uma
              finalidade determinada;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              marginRight: "1.8pt",
              textAlign: "justify",
              marginTop: "6pt",
              marginBottom: "6pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Transferência Internacional de Dados: transferência de dados pessoais para
              país estrangeiro ou organismo internacional do qual o país seja membro;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              marginRight: "1.8pt",
              textAlign: "justify",
              marginTop: "6pt",
              marginBottom: "6pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Uso Compartilhado de Dados: comunicação, difusão, transferência
              internacional, interconexão de dados pessoais ou tratamento compartilhado
              de bancos de dados pessoais por órgãos e entidades públicos no cumprimento
              de suas competências legais, ou entre esses e entes privados,
              reciprocamente, com autorização específica, para uma ou mais modalidades
              de tratamento permitidas por esses entes públicos, ou entre entes
              privados;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Cookie: é um dado armazenado no disco rígido do usuário contendo
              informações sobre ele.&nbsp;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Contato
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Em caso de necessidade de contato com a GOMUS JUKEBOX, relativo a
              perguntas e dúvidas desta Política de Privacidade, o usuário poderá entrar
              encaminhar e-mail para ti@gomus.com.br.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Encarregado:
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Delano Amorim&nbsp;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
            </span>
          </p>
          <br />
          <br />
          <br />
          <br />
          <br />
      </div>
    </>
  );
}
