import React from 'react';
import { BsPencilSquare } from 'react-icons/bs';

import useGet from 'services/hooks/useGet';
import { useProfile } from 'services/profile/getProfile';

function ModalDetails({
  closeModal,
  openForm = () => {},
  identifier: companyIdentifier,
}) {
  const { user } = useProfile();
  const { data: company } = useGet({
    route: `/adm/company/${companyIdentifier}/show`,
  });

  const { name, slug, description, imagePublicPath, createdAtFormatted } = company || {};
  return (
    <>
      <main>
        <div className="flex flex-wrap">
          <div className="w-full lg:w-12/12 px-4">
            <div className="relative w-full mb-3">
              <p className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Logo
              </p>
              <p>
              {imagePublicPath && imagePublicPath.length > 0 && <img src={`${imagePublicPath}?rand={$Math.random()}`} alt={name}  style={{ maxHeight: '150px' }}  className="object-cover object-center"/>}
              </p>
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <p className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Título
              </p>
              <p>{name}</p>
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <p className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Slug
              </p>
              <p>{slug}</p>
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <p className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Descrição
              </p>
              <p>{description}</p>
            </div>
          </div>
        </div>
        <hr className="my-2 border-b-1 border-blueGray-300" />
        <div className="flex justify-end w-full mt-2">
          <button
            className="bg-black text-white text-xs p-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
            type="button"
            onClick={() => {
              openForm();
              closeModal();
            }}
          >
            <BsPencilSquare size={20} color="#fff" />
          </button>
        </div>
      </main>
    </>
  );
}

export default ModalDetails;
