import React from 'react';
import { BrowserRouter, Switch, Redirect } from 'react-router-dom';
import 'services/serviceWorker/swConfig';

// public
import Login from 'views/auth/Login';
import Company from 'views/admin/Company';
import Store from 'views/admin/Store';
import Feedback from 'views/admin/Feedback';
import ForgotPassword from 'views/auth/ForgotPassword';

import Profile from 'views/admin/Profile';
import Users from 'views/admin/Users';

import App from 'views/app/App';

// route
import Route from './Route';
import Dashboard from 'views/admin/Dashboard';
import Terms from 'views/app/Terms';
import Policy from 'views/app/Policy';

import PublicTerms from 'views/app/PublicTerms';
import PublicPolicy from 'views/app/PublicPolicy';

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        {/* public routes */}
        <Route path="/" exact component={Login} />

        <Route path="/forgotPassword" component={ForgotPassword} />

        <Route path="/public/terms" exact component={PublicTerms} />
        <Route path="/public/policy" exact component={PublicPolicy} />

        {/* private admin */}
        <Route isPrivate path="/admin/users/:role" component={Users} />
        <Route isPrivate path="/admin/profile" exact component={Profile} />
        <Route isPrivate path="/admin/company" exact component={Company} />
        <Route isPrivate path="/admin/store" exact component={Store} />
        <Route isPrivate path="/admin/feedback" exact component={Feedback} />

        {/* private app client */}
        <Route isPrivate path="/app" exact component={App} />
        <Route isPrivate path="/app/terms" exact component={Terms} />
        <Route isPrivate path="/app/policy" exact component={Policy} />
        <Route isPrivate path="/admin/dashboard" component={Dashboard} />

        <Route isPrivate path="/app/:identifier" exact component={App} />
        <Route path="/company/:slug" component={Login} />

        {/* redirect */}
        <Redirect from="*" to="/" />
      </Switch>
    </BrowserRouter>
  );
}
