import * as yup from 'yup';

export const schema = yup.object().shape({
  name: yup.string().required('Campo obrigatório'),
  streetCode: yup.string().required('Campo obrigatório'),
  streetName: yup.string().required('Campo obrigatório'),
  streetNumber: yup.string().required('Campo obrigatório'),
  streetDistrict: yup.string().required('Campo obrigatório'),
  streetComplement: yup.string(),
  state: yup.string().required('Campo obrigatório.'),
  city: yup.string().required('Campo obrigatório.'),
  company: yup.string().required('Campo obrigatório.'),
  token: yup.string().required('Campo obrigatório'),
  latitude: yup.string().required('Campo obrigatório'),
  longitude: yup.string().required('Campo obrigatório'),
});
