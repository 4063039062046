import React from 'react';
import { ErrorMessage } from 'formik';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import pt from "date-fns/locale/pt";
import Select from 'react-select';

registerLocale("pt", pt);

function Attributes({ handleBlur, values, handleChange, setFieldValue, role }) {
  const labelClassName =
    'block uppercase text-blueGray-600 text-xs font-bold mb-2 w-min whitespace-nowrap ';
  return (
    <>
      <div className="w-full  px-4">
        <div className="relative w-full mb-3">
          <span className={labelClassName} htmlFor="name">
            Nome
          </span>
          <input
            autoComplete="nope"
            value={values.name}
            name="name"
            id="name"
            type="text"
            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            onChange={handleChange}
            onBlur={handleBlur}
          />

          <ErrorMessage
            component="p"
            className="text-red-500 mb-4 font-light text-xs"
            name="name"
          />
        </div>
      </div>

      <div className="w-full px-4">
        <div className="relative w-full mb-3">
          <span className={labelClassName} htmlFor="email">
            E-mail
          </span>
          <input
            autoComplete="nope"
            name="email"
            id="email"
            value={values.email}
            type="text"
            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            onBlur={handleBlur}
            onChange={(e) => {
              handleChange(e);
            }}
          />

          <ErrorMessage
            component="p"
            className="text-red-500 mb-4 font-light text-xs"
            name="email"
          />
        </div>
        {role != 'player' && <>
          <div className="relative w-full mb-3">
            <span className={labelClassName} htmlFor="gender">
              Gênero
            </span>
            <Select
              handleBlur={handleBlur}
              name="gender"
              placeholder="Selecione o seu gênero"
              setFieldValue={setFieldValue}
              value={values.gender}
              isClearable
              onChange={option => {
                  setFieldValue("gender", option);
              }}
              options={[
                { value: 'MASCULINO', label: 'MASCULINO' },
                { value: 'FEMININO', label: 'FEMININO' },
                { value: 'OUTROS', label: 'OUTROS' },
                { value: 'PREFIRO_NAO_INFORMAR', label: 'PREFIRO NÃO INFORMAR' },
              ]}
            />
            <ErrorMessage
              component="p"
              className="text-red-500 mb-4 font-light text-xs"
              name="gender"
            />
          </div>
          <div className="relative w-full mb-3">
            <span className={labelClassName} htmlFor="birthdate">
              Data de nascimento
            </span>
            <DatePicker
              locale="pt"
              placeholderText="Preencha a data de nascimento"
              selected={values.birthdate && new Date(values.birthdate)}
              dateFormat="dd/MM/yyyy"
              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              name="birthdate"
              onChange={date => {
                setFieldValue("birthdate", date);
              }}
            />
            <ErrorMessage
              component="p"
              className="text-red-500 mb-4 font-light text-xs"
              name="birthdate"
            />
          </div>
        </>}
      </div>
    </>
  );
}

export default Attributes;
