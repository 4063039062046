import React, { useEffect, useState } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PageCard from 'components/Cards/PageCard';
import api from 'services/api';
import { FaFileCsv, FaMusic, FaUser } from 'react-icons/fa';

function Dashboard({ color = 'light' }) {
  const [data, setData] = useState({});

  useEffect(() => {
    api.get('adm/dashboard/data')
    .then(res => {
      setData(res.data)
    })
  }, [])

  return (
    <PageCard
      className="bg-opacity-0 p-px shadow-none"
      color={color}
    >
      <div className="flex">
        <div className="w-full md:w-1/4">
          <div className="w-full bg-gray-500 text-white rounded-t-lg p-2">
            Jukebox mês <button className="float-right" onClick={() => {
              api
              .get(`adm/dashboard/export`, { responseType: 'blob' })
              .then((response) => {
                const type = response.headers['content-type'];
                const blob = new Blob([response.data], { type });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'export.csv');
                document.body.appendChild(link);
                link.click();
                link.remove();
              })
            }}><FaFileCsv size="25" /></button>
          </div>
          <div className="w-full bg-white text-center rounded-b-lg p-2 text-black">
            <p className="text-center text-3xl mt-6"><FaUser className="inline-block" size="40" /> {data?.totalUsers}</p>
            <p className="text-md mt-2">total de usuários conectados</p>
            <p className="text-center text-3xl mt-6"><FaMusic className="inline-block" size="40" /> {data?.totalSongs}</p>
            <p className="text-md mt-2 mb-6">total de músicas selecionadas</p>
          </div>
        </div>
      </div>
    </PageCard>
  );
}

export default Dashboard;
