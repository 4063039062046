import React, { useEffect, useState } from 'react';
import { ErrorMessage, Formik } from 'formik';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import moment from "moment";
import useGet from 'services/hooks/useGet';
import api from 'services/api';
import { getValuesFormatted } from 'utils/format/formikValues';
import Attributes from 'components/Forms/UserForm/Attributes';
import Password from 'components/Forms/UserForm/Password';
import Tabs from 'components/Tabs';
import { schema } from './schema';
import Select from 'components/Forms/Select';

function ModalForm({
  closeModal,
  onSuccess = () => {},
  identifier: userIdentifier,
  role,
}) {
  const [openTab, setOpenTab] = useState(userIdentifier ? 1 : 0);
  const [minAndMaxPriority, setMinAndMaxPriority] = useState({
    min: 0,
    max: 0,
  });

  const { data: user, getEntity } = useGet({
    route: `/adm/user/${userIdentifier}/show`,
    isAutomatic: false,
  });

  const booleanSelect = [
    {
      value: true,
      label: 'Sim',
    },
    {
      value: false,
      label: 'Não',
    },
  ];

  useEffect(() => {
    if (userIdentifier) getEntity();
  }, []);

  const editPassword = ({ password, setSubmitting }) => {
    const path = `adm/user/${userIdentifier}/change-password`;
    api
      .post(path, { password })
      .then(() => {
        toast.success('Senha alterada com sucesso');
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const onSubmit = ({ values, setSubmitting }) => {
    const path = userIdentifier
      ? `adm/user/${userIdentifier}/edit`
      : `adm/user/${role}/new`;
    api
      .post(path, values)
      .then((e) => {
        toast.success(
          `Usuário ${userIdentifier ? 'editado' : 'criado'} com sucesso`
        );

        onSuccess();
        closeModal();
      })
      .catch(() => {
        setSubmitting(false);
      })
      .finally(() => {
        if (userIdentifier) {
          setSubmitting(false);
        }
      });
  };

  const [storeList, setStoreList] = useState([]);

  useEffect(() => {
    api.get('adm/store/list-all')
    .then(res => {
      setStoreList(res.data.map(store => {
        return {
          value: store.identifier,
          label: store.name
        }
      }))
    })
  }, [])

  return (
    <>
      {(!userIdentifier || (userIdentifier && !!user)) && (
        <>
          {userIdentifier && (
            <Tabs
              selected={openTab}
              setSelected={setOpenTab}
              buttons={[
                { title: 'Atributos' },
                { title: 'Mudar Senha' },
              ]}
            />
          )}
          <Formik
            enableReinitialize
            initialValues={{
              name: user?.name || '',
              email: user?.email || '',
              store: user?.store?.identifier || '',
              password: user?.password || '',
              confirmPassword: user?.confirmPassword || '',
              gender: user?.genderReadableValue ? { label: user.genderReadableLabel, value: user.genderReadableValue } : '',
              birthdate: user?.birthdate || '',
            }}
            validationSchema={schema(openTab, role === 'admin')}
            onSubmit={(values, { setSubmitting }) => {
              var valuesToFormat = {
                email: values.email,
                name: values.name,
                store: values.store,
                password: values.password
              };

              var aux = getValuesFormatted(valuesToFormat);

              if (values?.birthdate) {
                aux = {
                  ...aux,
                  birthdate: moment(values.birthdate).format("YYYY-MM-DD")
                };
              }

              if (values?.gender) {
                aux = {
                  ...aux,
                  gender: values.gender.value
                };
              }

              // edit
              delete aux.confirmPassword;
              if (openTab === 1) {
                delete aux.password;
                onSubmit({ values: aux, setSubmitting });
                return;
              }
              if (openTab === 2) {
                editPassword({ password: aux.password, setSubmitting });
                return;
              }
              // create
              onSubmit({ values: aux, setSubmitting });
            }}
          >
            {({
              values,
              handleChange,
              handleBlur,
              setFieldValue,
              handleSubmit,
              setFieldError,
              isSubmitting,
            }) => (
              <form
                style={userIdentifier ? { minHeight: 210, maxWidth: 600 } : {}}
                className="flex flex-wrap sm:w-full "
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                {(!userIdentifier || openTab === 1) && (
                  <>
                    <Attributes
                      setFieldError={setFieldError}
                      setFieldValue={setFieldValue}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      values={values}
                      role={role}
                    />
                    
                    {role == 'player' && (
                      <div className="w-full px-4">
                        <div className="relative w-full mb-3">
                          <span className='block uppercase text-blueGray-600 text-xs font-bold mb-2 w-min whitespace-nowrap' htmlFor="store">
                            Loja
                          </span>
                          <Select
                            name="store"
                            placeholder=""
                            value={values.store}
                            className="mt-1"
                            options={storeList}
                            setFieldValue={setFieldValue}
                            handleBlur={handleBlur}
                          />

                          <ErrorMessage
                            component="p"
                            className="text-red-500 mb-4 font-light text-xs"
                            name="store"
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}

                {(!userIdentifier || openTab === 2) && (
                  <Password
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    values={values}
                  />
                )}

                <div className="flex justify-end self-end w-full mt-2">
                  <button
                    disabled={isSubmitting}
                    className="bg-black text-white text-sm font-bold p-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
                    type="submit"
                  >
                    {isSubmitting ? (
                      <ClipLoader color="#fff" />
                    ) : (
                      <>{userIdentifier ? 'EDITAR' : 'ADICIONAR'}</>
                    )}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </>
      )}
    </>
  );
}

export default ModalForm;
